































import Vue from 'vue';
import swal from 'sweetalert2';

import RCP from '@/api/RCP';

import { ErrorResponse } from '@/types/Errors';

export default Vue.extend({
    name: 'Partners',
    data() {
        return {
            partners: []
        };
    },
    created() {
        this.getPartners();
    },
    methods: {
        getPartners() {
            RCP({
                id: 'getPartners',
                method: 'PartnerList'
            }).then((response: any) => {
                this.partners = response;
            }).catch((error: ErrorResponse) => {
                if (error.show) {
                    swal.fire({
                        icon: 'error',
                        title: 'Ошибка',
                        text: error.message,
                        confirmButtonText: 'Ок',
                        confirmButtonColor: '#605ab9'
                    });
                }
            });
        }
    }
});
